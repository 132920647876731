.marginBottom {
	margin-bottom: rem($baseLineHeight);
}

.highlight {
	color: $secondary;
}

.noPaddingBottom{
	padding-bottom: 0!important;
}
.noPaddingTop{
	padding-top: 0!important;
}
.alignVertical{
	@include breakpoint(huge, min){
	    transform: translateY(-50%);
	    top: 50%;
	    position: relative;
	}
}
.btn {
	@extend %animatedTransform;
	background: $dark;
	border: none;
	color: $light;
	cursor: pointer;
	display: table;
	font-size: rem($baseFontSize);
	line-height: rem($baseLineHeight);
	padding: 0.8rem 1rem;
	text-align: center;
	text-decoration: none;
	font-family: $displayFont;
	margin: .5rem 0;
	display: inline-block;
	font-size: rem(16px);
	background: $primary;
    padding: 1rem 1.25rem;
    	&:before{
			content: map-get($iconMap, plus);
			font-family: $iconFont;
			position: relative;
			padding-right: rem($baseGap / 2);
		}
    &.white{
    	background: $light;
    	color: $dark;
    }

	&:hover,
	&:active,
	&:focus {
		background: lighten($dark, 15%);
		color: $light;
	}

	*[class^="icon"] {
		margin-left: 0.5rem;
		vertical-align: middle;
	}
}

.clear {
	clear: both;
	float: none;
}

.clearfix {
	@include pie-clearfix;
}

.hidden {
	display: none;
}

.hideText {
	@include hideText();
	display: inline-block;
	overflow: hidden;
	width: 0;
}

.floatLeft {
	float: left;
}

img.floatLeft {
	margin-right: 1rem;
}

.floatRight {
	float: right;
}

img.floatRight {
	margin-left: 1rem;
}

.fluid {
	height: auto;
	width: 100%;
}

.nonFluid {
	width: auto !important;
}

.alignLeft {
	text-align: left;
}

.alignRight {
	text-align: right;
}

.alignCenter {
	text-align: center;
}

.wide {
	width: 100%;
	display: block;
}

/* 16:9 video resolutions */
.videoContainer {
	height: 0;
	overflow: hidden;
	padding-bottom: 56.25%;
	position: relative;

	iframe {
	border: none;
	height: 100%;
	position: absolute;
	width: 100%;
	}
}

body {
	&:before {
	$content: "default:" + $bpContext;
	width: str_length($content) * 6pt + $baseGap * 2;

	@each $point, $width in $gridMap {
		$content: $content +
		"...." +
		$point +
		":" +
		nth(map-get(map-get($gridMap, $point), width), 1);

		@include breakpoint($point) {
		width: str_length($content) * 6pt + $baseGap * 2;
		}
	}

	content: "#{$content}";
	display: none !important; /* Prevent from displaying. */
	}

	&.devmode:before,
	&.debug:before {
	background: $alert;
	border-color: $alert;
	border-style: solid;
	border-width: 0 rem($baseGap);
	color: $light;
	display: block !important;
	font-family: Courier;
	font-size: 10pt;
	left: 0;
	line-height: 2.5;
	overflow: hidden;
	position: absolute;
	right: 0;
	text-align: center;
	top: 0;
	white-space: nowrap;
	z-index: 99999;
	}
	&:hover:before {
	opacity: 0.5;
	pointer-events: none;
	}
}

// Animations
%animatedTransform {
	transition: 0.3s;
}


.notificationArea
{
	position: fixed;
	bottom:20px;
	left:20px;

	> :not(:last-child)
	{
		margin-bottom:$baseGap;
	}

	.notification
	{
		position: relative;
		transition:300ms all ease-in-out;
		overflow: hidden;
		padding: 1em;
		background: $alert;
		font-size: 12px;
		line-height: 16px;
		box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
		width:290px;
		max-width:calc(100vw - 40px);
		transform-origin: left center;

		&, a
		{
			color: $light;
		}

		.close
		{
			position: absolute;
			top: 5px;
			right: 8px;
			cursor: pointer;
			color:#fff;
			font-size:1.2rem;
		}

		&.hide
		{
			opacity:0;
			visibility: hidden;
			transform:scale(0);
			max-height:0;
			height:0;
			padding-top:0;
			padding-bottom:0;
		}
	}
}