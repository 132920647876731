// Form styles
form.default {
	overflow: hidden;
	width: 100%;

	::-webkit-input-placeholder {
		/* Chrome/Opera/Safari */
		color: $dark;
	}

	::-moz-placeholder {
		/* Firefox 19+ */
		color: $dark;
	}

	:-ms-input-placeholder {
		/* IE 10+ */
		color: $dark;
	}

	:-moz-placeholder {
		/* Firefox 18- */
		color: $dark;
	}

	fieldset {
		border: none;
		margin: 0 0 3.5rem 0;
		@include breakpoint(medium, max){
			margin: 0;
		}
	}

	label,
	input,
	textarea,
	select,
	button,
	[type="submit"] {
		min-height: 3.5rem;
	}

	label {
		color: inherit;
		display: block;
		padding: 1rem 0 0.6rem;
		position: relative;

		small {
			color: $alert;
			display: block;
			line-height: 0.5rem;
			position: absolute;
		}

		&[for="Datenschutz"] {
			@extend .marginBottom;
			float: left;
			width: calc(100% - #{rem(30px)});
		}
	}

	[type="text"],
	[type="tel"],
	[type="email"],
	[type="file"],
	input,
	textarea,
	select {
		@extend %animatedTransform;
		background: $light;
		border: $baseBorder;
		border-color: $primary;
		color: $dark;
		display: block;
		font-family: $mainFont;
		font-size: rem($baseFontSize);
		line-height: rem($baseLineHeight);
		margin: 0 0 0.5rem 0;
		padding: 0.8rem;
		position: relative;
		resize: none;
		width: 100%;
		border-radius: 0;

		&:focus {
			background: darken(#f8f8f8, 2%);
			outline: none;
		}
	}

	textarea {
		/* fake line-height */
		min-height: 7.5rem;
		padding-top: 0.9rem;

		$inputgroup-count: 3;

		&[name="Nachricht"] {
			min-height: 7.5rem * $inputgroup-count - 4rem;
		}
	}

	[type="checkbox"] {
		display: inline-block;
		float: right;
		margin: 0 0 0.5rem rem(15px);
		padding: 0;
		width: rem(15px);
		height: rem(15px);

		body.iexplore & {
			border: none;

			&:focus {
				background: none;
			}
		}
	}

	[type="radio"] {
		clear: none;
		float: left;
		width: rem(15px);
		margin: 0.4rem 0.8rem 0 0;
		min-height: auto;
	}

	button,
	[type="submit"] {
		@extend .btn;
		margin: 0 0 1.75rem;
		width: 100%;
	}
}

/*=VALIDATION */

.specialfield {
	display: none !important;
	visibility: hidden !important;
}

#newsletter-info {
	background: $alert;
	color: $light;
	font-weight: bold;
	margin-bottom: $baseLineHeight;
	padding: $baseGap;

	*:last-of-type {
		margin-bottom: 0;
	}
}

img[name="vimg"] + input[name="imgverify"] {
	float: right;
}
@media only screen and (max-height: 560px) {
	@media only screen and (min-width: 800px)  {
		.services {
			.sub {
				display: grid;
				grid-template-columns: 150px 150px;

				li {
					a {
						white-space: inherit !important;
					}
				} 
				

				.nav-back {
					grid-column-start: 1;
					grid-column-end: 3;
				}
			}
		}
	}
}

.evercaptcha{
	margin-top: rem(20px);
}