// HEADER
//////////////////////////////

.topBar {
  display: none;
  @include breakpoint(giant) {
    display: block;
    background: $grey;
    position: fixed;
    width: 100%;
    padding: 2rem 0;
    top: 0;
    z-index: 1000;

    ul {
      &.displayFlex {
        display: flex;
        li {
          padding: 0 0.75rem 0;
          strong {
            font-size: rem(18px);
          }
          @include breakpoint(full) {
            padding: 0 1rem 0;
            font-size: rem(16px);
          }
          &:last-child {
            padding-left: 4rem;
          }
        }
      }
    }
    a {
      &.btn {
        &.long {
          position: absolute;
          right: 0;
          top: 0;
          margin-top: 0;
          padding: 1.75rem 6rem 1.75rem 2rem;
          font-family: $mainFont;
          font-weight: 300;
          @include breakpoint(full, min) {
            padding: 1.75rem 8.75rem 1.75rem 2rem;
            font-size: rem(19px);
          }
        }
      }
    }
  }
}

#pageHeader {
  position: relative;
  padding: 0 rem($baseGap);
  width: 100%;
  top: $barHeight;
  margin-bottom: 4rem;
  position: relative;
  overflow: hidden;
  @include breakpoint(mini, max) {
    padding: 0 rem($baseGap) 4rem;
  }
  @include breakpoint(superTiny, max) {
    padding: 0 rem($baseGap) 1rem;
  }
  @include breakpoint(superTiny, min) {
    margin-bottom: 5rem;
  }
  @include breakpoint(tiny, min) {
    margin-bottom: 4rem;
  }

  @include breakpoint(medium) {
    padding: 0;
  }
  .branding {
    width: 100%;
    height: auto;
    max-width: 250px;
    display: block;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    background: $light;
    padding: 1.5rem;
    @include breakpoint(medium, min) {
      display: inline-block;
      position: fixed;
      padding: 0.25rem;
      top: rem(12px);
      left: rem(5px);
      z-index: 9999;
      background: transparent;
      @include breakpoint(medium) {
        padding-left: rem(8px);
      }
      @include breakpoint(full) {
        padding-left: 0;
      }
      @include breakpoint(giant, min) {
        top: 5.5rem;
      }
      @include breakpoint(full, min) {
        max-width: 300px;
        top: 6.25rem;
      }

      img {
        @include breakpoint(medium, min) {
          max-width: 70%;
        }
        @include breakpoint(large, min) {
          max-width: 90%;
        }
        @include breakpoint(full, min) {
          max-width: 120%;
          transition: 0.3s ease-out;
          body.scrollingDown & {
            max-width: 80%;
          }
        }
      }
    }
    @include breakpoint(full) {
      left: 50%;
      transform: translateX(#{rem($rowMaxWidth / -2)});
    }
  }
  .row {
    &.moreWidth {
      @include breakpoint(huge, min) {
        max-width: rem(1550px);
      }
      .claim {
        padding: 1rem;
        position: absolute;
        top: 3rem;
        display: block;
        z-index: 999;
        @include breakpoint(tiny, min) {
          background: $light;
          width: 95%;
          padding: 1.5rem 1rem;
          top: 2.5rem;
        }
        @include breakpoint(small, min) {
          top: 3rem;
          background: $light;
          width: 92%;
          padding: 4.5rem 2rem 4.5rem 1rem;
        }
        @include breakpoint(medium, min) {
          top: 7rem;
          background: $light;
          left: 2%;
          width: 81%;
          padding: 2.5rem;
        }
        @include breakpoint(large, min) {
          top: 11rem;
          background: $light;
          left: 2%;
          width: 67%;
          padding: 2.5rem;
        }
        @include breakpoint(giant, min) {
          top: 11rem;
          background: $light;
          left: 3%;
          width: 53%;
          padding: 4.5rem 2.5rem;
        }
        @include breakpoint(huge, min) {
          top: 16rem;
          background: $light;
          left: 4%;
          width: 50%;
          padding: 5rem 2.5rem;
        }
        @include breakpoint(full, min) {
          top: 16rem;
          background: $light;
          left: 5%;
          width: 47%;
          padding: 5.5rem 3rem;
        }
        @include breakpoint(cut, min) {
          top: 16rem;
          background: $light;
          left: 5%;
          width: 47%;
          padding: 5.5rem 4rem;
        }
        @media (min-width: 640px) and (max-width: 699px) {
          padding-top: rem(20px);
          padding-bottom: rem(20px);
        }
        @media (min-width: 801px) and (max-width: 882px) {
          top: 3rem;
        }
        @media (min-width: 1200px) and (max-width: 1500px) {
          padding-top: rem(30px);
          padding-bottom: rem(30px);
        }
        blockquote {
          font-weight: 300;
          background: $light;
          padding: 1rem;
          margin-bottom: 0.5rem;
          @include breakpoint(tiny, max) {
            text-align: center;
            font-size: rem(18px);
          }
          @include breakpoint(tiny, min) {
            font-size: rem(18px);
            padding-top: 0;
          }
          @include breakpoint(small, min) {
            text-align: left;
            font-size: rem(24px);
          }
          @include breakpoint(medium, min) {
            padding-left: 0;
            font-size: rem(28px);
            line-height: 1.2em;
          }
          @include breakpoint(giant, min) {
            padding-left: 0;
            font-size: rem(36px);
            line-height: 1.2em;
          }
          @include breakpoint(full, min) {
            padding-left: 0;
            font-size: rem(43px);
            line-height: 1.2em;
          }
        }
        a,
        span {
          &.btn {
            margin: 0.5rem 0;
            display: inline-block;
            font-size: rem(16px);
            background: $primary;
            padding: 1rem 1.25rem;
            @include breakpoint(superTiny, max) {
              display: block;
              margin-top: rem(15px);
              position: relative;
            }
            @include breakpoint(medium, min) {
              font-size: rem(18px);
            }
            @include breakpoint(giant, min) {
              font-size: rem(19px);
            }
            @include breakpoint(huge, min) {
              font-size: rem(20px);
            }
            &:before {
              content: map-get($iconMap, plus);
              font-family: $iconFont;
              position: relative;
              padding-right: rem($baseGap / 2);
            }
            &.requestIt {
              font-weight: 300 !important;
              @include breakpoint(superTiny, max) {
                display: none;
              }
              @include breakpoint(superTiny) {
                padding-right: rem(18px);
                padding-left: rem(18px);
              }
              @include breakpoint(small) {
                font-weight: 700;
                padding-right: rem(50px);
                padding-left: rem(50px);
                // margin-left: 8px;
              }
              @include breakpoint(giant) {
                padding-right: rem(40px);
                padding-left: rem(41px);
              }
              @include breakpoint(full) {
                padding-right: rem(54px);
                padding-left: rem(55px);
              }
              @include breakpoint(cut) {
                padding-right: rem(24px);
                padding-left: rem(22px);
              }
              &:hover {
                background: rgb(140, 140, 140);
              }
            }
            &.phoneColor {
              padding-top: rem(18px);
              padding-bottom: rem(16px);
              background: $secondary;
              margin-left: 0.5rem;
              color: $light;
              text-decoration: none;
              @include breakpoint(tiny, max) {
                float: right;
              }
              @include breakpoint(superTiny, max) {
                display: block;
                width: 100%;
              }
              @include breakpoint(superTiny) {
                padding-right: rem(23px);
                padding-left: rem(23px);
              }
              @include breakpoint(medium) {
                padding-right: rem(23px);
                padding-left: rem(20px);
              }
              &:hover {
                @include breakpoint(large, max) {
                  background: rgb(140, 140, 140);
                }
              }
              strong {
                @include breakpoint(small, max) {
                  display: none;
                }
              }
              &:before {
                content: none;
              }
            }
            a,
            span {
              &.phone {
                color: $light;
              }
            }
          }
        }
        .buttonsContainer {
          display: flex;
        }
      }
      img {
        @include breakpoint(superTiny, max) {
          height: rem(300px);
        }
      }
    }
  }
}

// MAIN CONTENT
//////////////////////////////
.littleMargin {
  margin: 1rem 0;
}
.row {
  padding: 2rem 0;
  @include breakpoint(tiny) {
    padding: 4rem 0;
  }
  @include breakpoint(medium) {
    padding: 5rem 0;
  }
  @include breakpoint(large) {
    padding: 5.5rem 0;
  }
  @include breakpoint(giant) {
    padding: 6.5rem 0;
  }
  .row {
    padding: 0;
  }
  &.noPadding {
    padding: 0;
  }
}
main {
  display: block;
  overflow: hidden;
  p,
  ol,
  ul,
  dl,
  table {
    @extend .marginBottom;
  }

  .borderRight {
    @include breakpoint(small, min) {
      border-right: rem(1px) solid rgba($dark, 0.2);
    }
  }
  .reviewUs {
    padding-left: rem($baseGap * 4);
  }
  ul:not(.unstyledList),
  dl:not(.unstyledList) {
    @extend .styledList;
  }

  .tinyVisible {
    @include breakpoint(tiny, max) {
      display: none;
    }
  }

  .greyBackground {
    background: $grey;
    // padding: 2rem 0;
    h3 {
      font-weight: 400;
    }

    .whiteBox {
      background: $light;
      padding: 2.25rem 2.25rem 3rem;
      margin: 1rem 0;
      border-bottom: rem(2px) solid $primary;
      position: relative;
      transform: translate(-2rem, 0);
      opacity: 0;
      visibility: hidden;
      @include breakpoint(small, min) {
        min-height: rem(385px);
      }
      @include breakpoint(huge, min) {
        min-height: rem(360px);
        padding: 4.25rem 5.25rem 3rem;
      }
      &.right {
        transform: translate(2rem, 0);
      }

      @for $i from 1 through 4 {
        &:nth-of-type(4n + #{$i}) {
          transition: all 0.5s (0.65s * $i) ease-in-out;
        }
      }

      &.lazyLoaded {
        opacity: 1;
        visibility: visible;
        transform: none;
      }
      h4 {
        font-weight: 300;
        font-size: rem(24px);
      }
      a {
        &.greenPlus {
          text-decoration: none;
          background: $primary;
          padding: 0.5rem 1rem;
          color: $light;
          bottom: 0;
          position: absolute;
          right: 0;
          &:hover,
          &:focus {
            // background: rgb(140, 140, 140);
            color: $dark;
          }
        }
      }
      .hr {
        width: 50%;
        margin: 1.75rem 0;
      }
      &.orange {
        h4 {
          font-weight: 600;
          @include breakpoint(full, min) {
            margin-top: 2rem;
          }
        }
        p {
          margin-bottom: 0;
        }
        border-bottom: none;
        background: $secondary;
        color: $light;
        padding: 2.25rem;
        @include breakpoint(small, min) {
          min-height: rem(220px);
        }
        @include breakpoint(medium, min) {
          min-height: rem(264px);
        }
        @include breakpoint(large, min) {
          min-height: rem(280px);
        }
        @include breakpoint(giant, min) {
          min-height: rem(340px);
          padding: 2rem 4.5rem;
        }
        @include breakpoint(huge, min) {
          min-height: rem(399px);
        }

        a {
          color: $light;
          text-decoration: underline;
        }
      }
    }
  }
  .newsBox {
    background: $primary;
    padding: 3.5rem 1.5rem;
    color: $light;
    @include breakpoint(giant, min) {
      padding: 5.5rem 4.5rem;
    }
    @include breakpoint(huge, min) {
      padding: 6.5rem;
      h3 {
        font-size: rem(40px);
        margin-bottom: 2.5rem;
        font-weight: 300;
      }
      a {
        &.btn {
          margin-top: 2rem;
          font-size: rem(20px);
        }
      }
    }
  }
  .col {
    &.imageBox {
      display: flex;
      padding: 0;
    }
  }

  dl {
    &.floatList {
      dt,
      dd {
        border-top: rem(1px) solid rgba($dark, 0.2);
        padding: 1rem 0.5rem 1rem rem(30px) !important;
        position: relative;
        width: 50%;
        font-weight: 400;
        @include breakpoint(giant, max) {
          font-size: 13px;
        }
        &:before {
          content: map-get($iconMap, check);
          font-family: $iconFont;
          color: $darkBlue;
          position: absolute;
          display: inline-block;
          font-weight: 500 !important;
          left: rem(10px);
        }
      }
      dd {
        margin-bottom: 0 !important;
        border-left: rem(1px) solid rgba($dark, 0.2);
      }
      dt {
        padding-top: 0;
      }
      &:last-child {
        border-bottom: rem(1px) solid rgba($dark, 0.2);
      }
    }
  }
}
span {
  &.hr {
    &.tinyVisible {
      @include breakpoint(full, min) {
        visibility: hidden;
        opacity: 0;
      }
    }
  }
}
.contactUs {
  h4 {
    font-weight: 500;
  }
  ul {
    li {
      @include breakpoint(small, min) {
        font-size: rem(14px);
      }
      @include breakpoint(large, min) {
        font-size: rem(16px);
      }
    }
  }
  .borderRight {
    @include breakpoint(full, min) {
      border-right: rem(1px) solid rgba($dark, 0.2);
    }
  }
  .paddingLeft {
    @include breakpoint(full, min) {
      padding-left: 8rem;
    }
  }
  .socialBar {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    a {
      position: relative;
      bottom: 0;
      transition: 0.3s ease-out;
      &.faceBook {
        padding-right: rem(15px);
        border-right: rem(1px) solid rgba($dark, 0.3);
        @include breakpoint(huge, min) {
          padding-right: rem(40px);
        }
        &:hover {
          opacity: 0.6;
        }
        img {
          bottom: rem(-8px);
          position: relative;
        }
      }
      &.yelp {
        margin-left: rem(15px);
        @include breakpoint(huge, min) {
          margin-left: rem(40px);
        }
        &:hover {
          opacity: 0.6;
        }
        img {
          bottom: rem(6px);
          position: relative;
        }
      }
    }
  }
}

.responsiveBackground {
  @include responsiveImage("bgHeader.jpg", (tiny, medium), true);
  padding: 4em 1.2em;
  color: $light;

  &.lazyLoaded {
    background-size: cover;
  }
}
.verticalCenter {
  @include breakpoint(small, min) {
    margin-top: 1rem;
  }
  @include breakpoint(large, min) {
    margin-top: 0;
  }
}
.googleMaps {
  height: rem(500px);
  iframe {
    height: rem(500px);
  }
}

.sameHeight {
  @include breakpoint(medium, min) {
    min-height: rem(265px);
  }
}
.minHeight {
  display: flex;
  @include breakpoint(medium, min) {
    min-height: rem(426px);
  }
  @include breakpoint(large, min) {
    min-height: rem(451px);
  }
}
span {
  &.tillLarge {
    @include breakpoint(large, min) {
      display: none;
    }
  }
}

// FOOTER
//////////////////////////////

#pageFooter {
  background: $light;
  // padding: 2rem 0 rem(70px) 0;
  padding: 0 0 rem(70px) 0;
  @include breakpoint(tiny) {
    padding: 0;
  }
  .row {
    padding: 0;
    .col {
      @include breakpoint(medium, max) {
        padding: 0;
      }
      .navi {
        display: flex;
        justify-content: center;
        flex-flow: column nowrap;
        width: 100%;
        @include breakpoint(small, min) {
          flex-flow: row nowrap;
          justify-content: flex-start;
        }

        li {
          @include breakpoint(small, max) {
            border-bottom: rem(1px) solid rgba($dark, 0.3);
          }
          a {
            color: $dark;
            text-decoration: none;
            display: block;
            text-align: center;
            padding: 1.5rem 0;
            transition: 0.3s ease-out;
            @include breakpoint(small, min) {
              padding: 1.5rem;
            }
            &:hover,
            &.active {
              background: $secondary;
              color: $light;
            }
          }
          &:last-child {
            a {
              border-bottom: none;
            }
          }
        }
      }
    }
  }
}

body:not(.index) {
  #pageHeader {
    .claim {
      background: transparent !important;
      top: 3rem !important;
      display: block;
      z-index: 999;
      @include breakpoint(medium, min) {
        top: 8rem !important;
      }
      blockquote {
        display: none;
      }
    }
  }
}

body.cmsBackend {
  #pageNavi {
    display: none;
  }
  .branding {
    display: none !important;
  }
}

#WidgetSealEXSNpx {
  transform: translateX(-50%);
  left: 72%;
  position: relative;
}

.swiper-container {
  padding-left: 1.125rem !important;
  padding-right: 1.125rem !important;
}

body.news {
  .greyBackground {
    background: rgba($medium, 0.5);
    padding: 2rem;
    margin: 1rem;
  }
}

.contact-row {
  justify-content: flex-start;
  dt,
  dd {
    background: rgba($medium, 0.5) !important;
  }
}
