// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: medium; // Breakpoint – switch to Desktop version

$desktopHover: false; // Set Hover to reach Submenus for Desktop (true, false)

// NAVIGATION
//////////////////////////////////////////////

$navBg: $light; // Navigation Background
$navColor: $dark; // Navigation Link Color

@mixin navButton {
	// Default Link Styles
	padding: 1em rem($baseGap);
	text-decoration: none;
	color: $navColor;
}
%buttonHover {
	// Link Hover
	color: $light;
	background-color: $secondary;
}
%buttonActive {
	// Link Active
	color: $light;
	background-color: $secondary;
}
%prevIcon {
	// Icon to previus Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);
	transform: rotateY(180deg);
}
%nextIcon {
	// Icon to next Submenu
	font-family: $iconFont;
	content: map-get($iconMap, plus);
}
$closeIcon: map-get($iconMap, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$barHeight: 50px; // height (in px)
$barPos: top; // position (top, bottom)
$barHide: true; // if bar hides on scroll (true, false)

$barBg: $primary; // background-color
$barColor: $light; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include pageNavi {
	background-color: $navBg;
	z-index: 1001;

	nav {
		@include ul {
			&.navi {
				li {
					@include breakpoint(medium, max){
						border-bottom: rem(1px) solid $medium;
					}
					padding: .75rem;
					@include navButtons {
						@include navButton;
					}
					@include hasSub {
						@include sub {
							background: $navBg;
							li{
								@include breakpoint(medium, max){
									border-bottom: rem(1px) solid $medium;
								}
								padding: .75rem;
								@include breakpoint(medium, max){
                                    padding: rem(2px);
                                }
								a{
									@include breakpoint(medium, max){
										@media (max-height:700px){
											font-size: rem(14px);
											padding-top: rem(8px);
											padding-bottom: rem(8px);
										}
									}
								}
								&.nav-back{
									@include breakpoint(medium, max){
										@media (max-height:700px){
											padding-top: 0!important;
										}
									}
								}
							}

							&:before {
								@extend .h5;
								padding: 1em rem($baseGap);
								background-color: darken($navBg, 6%);
								margin: 0;
							}
							
						}
					}
					@include navBack {
					    padding: 1em 1.125rem!important;
					}
				}
			}
		}
	}
}

// Navigation Desktop Styles
////
@include breakpoint($break) {
	@include desktopVersionFunctionality;

	#pageNavi {
		@include breakpoint(giant, min){
			top: 5rem;
			transition: .3s ease-out;
			body.scrollingDown &{
    			 border-bottom: rem(1px) solid rgba($dark, .3);
    		}
		}
		nav {
			max-width: $rowMaxWidth;
    		margin: 0 auto;

			ul {
				&.navi {
					@include breakpoint(medium, min){
					    display: flex;
					    justify-content: flex-end;
					    width: 100%;						
					}
					@media (min-width: 800px) and (max-width: 1420px){
						padding-right: rem(10px);
					}
					li {
						@include breakpoint(medium, min){
						    padding: .75rem 0.25rem;
						}
						@include breakpoint(large, min){
						    padding: 1.25rem 0.25rem;
						}
						@include breakpoint(full, min){
						    padding: 2.5rem 0.25rem;
						    transition: .3s ease-out;
						    body.scrollingDown &{
				    			 padding: 1.5rem 0.25rem;
				    		}
						}
						a,span{
							@include breakpoint(medium, min){
								 padding: .75em 1.125rem;
								 background: $grey;
								 border-bottom: none;
							}
						}
						&.hasSub {

							span{
								@include breakpoint(medium, min){
								 padding: .75em 1.125rem;
								 border-bottom: none;
							}
							}
							.sub {
								
								li{
									@media (max-height:700px){
										padding-top: rem(5px)!important;
										padding-bottom: rem(5px)!important;
									}
									&:last-of-type{
										@media (max-height:700px){
												padding-bottom: rem(10px)!important;
										}
									}
								}	
							}
						}
						&.nav-back {
						}
					}
				}
			}
		}
	}
}

// Quickbar Styles
////
#quickbar {
	@extend %animatedTransform;
	background-color: $barBg;
	color: $barColor;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: rem($baseGap);
	z-index: 1000;

	@include breakpoint($break) {
		display: none;
	}

}
