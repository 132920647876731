// Relativ path to images folder (used in the image-url() function)
$imagePath: "../images/" !default;

// +++++ Font settings
// Font size of main content (in px)
$baseFontSize: 16px;

// Line height of main content (in px)
$baseLineHeight: 24px;

// Headline font sizes (in px)
$h1Size: 32px;
$h2Size: 28px;
$h3Size: 24px;
$h4Size: 22px;
$h5Size: 18px;
$h6Size: 16px;

// Font stacks: $mainFont for simple content, $displayFont for headlines etc., $iconFont for icons
$mainFont:    "Open Sans", sans-serif;
$displayFont: "Raleway", sans-serif;
$iconFont:   sans-serif;
$iconFont:   "icomoon";

// +++++ Design & Layout
// Colours for your design.
$primary:       #b0c526;
$secondary:     #fdb813;
$light:         #fff;
$medium:        #e9e9e9;
$grey:          #f6f6f6;
$dark:          #666666;
$border:        #bbb;
$alert:         #D85D00;
$darkGreen:     #3e9942;
$darkBlue:      #38657a;

// Horizontal padding left and right of grid columns
$baseGap: 18px;

// Grid row max-width
$rowMaxWidth: 1400px;

// Grid columns
$columnCount: 12;

// Standard border for your design
$baseBorder: 1px solid $border;

// Standart outline styles for focus states
$outline: 1px dotted $alert;

// Assume a different base font size for breakpoint em calculation (default: 16px)
$bpContext: 16px;

// +++++ Miscellaneous
// text-indent value when using hideText(): "right" = positive value, "left" = negative value
$hideTextDirection: "right";

// $iconMap: (
//     times: "×",
//     bars: "≡",
//     minus: "-",
//     angle-right: "❯",
//     plus: "+",
//     angle-up: "↑",
//     exclamation: "!"
// );

// EXAMPLE FOR USE WITH ICONFONT
$iconMap: (
    times: "\f00e",
    bars: "\f039",
    minus: "\f068",
    angle-right: "\f105",
    plus: "+",
    angle-up: "\f106",
    exclamation: "\f12b",
    check: "\f05d"
);


/*  Grid Map 
    Define your breakpoints and grid classes below.
    Only generate the gridclasses you really need to reduce filesize.
    example:
    tiny: ( <-- "breakpoint name"
        width:  em(480px, $bpContext), <-- "breakpoint width"
        cols: (5,6,12), <-- "columns" result generates .tiny-5, .tiny-6 and .tiny-12
        prefix: (2,4), <-- "prefix" result generates .tiny-prefix-2 and .tiny-prefix-4
        suffix: (6,8), <-- "suffix" result generates .tiny-suffix-6 and .tiny-suffix-8
    )
*/
$gridMap: (
    mini: (
        width:  em(400px, $bpContext),
        cols: (4,5,6,7,8,12)
    ),
    superTiny: (
        width:  em(440px, $bpContext),
        cols: (4,5,6,7,8,12)
    ),
    tiny: (
        width:  em(480px, $bpContext),
        cols: (4,5,6,7,8,12)
    ),
    small: (
        width:  em(640px, $bpContext),
        cols: (4,5,6,7,8,12)
    ),
    medium: (
        width:  em(800px, $bpContext),
        cols: (4,5,6,7,8,12)
    ),
    large: (
        width:  em(992px, $bpContext),
        cols: (4,5,6,7,8,12)
    ),
    giant: (
        width:  em(1200px, $bpContext),
        cols: (4,5,6,7,8,12)
    ),
    huge: (
        width:  em(1364px, $bpContext),
        cols: (4,5,6,7,8,12)
    ),
    full: (
        width:  em(1520px, $bpContext),
        cols: (4,5,6,7,8,12)
    ),
    cut: (
        width:  em(1620px, $bpContext),
        cols: (4,5,6,7,8,12)
    )
);